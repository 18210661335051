<!--  -->
<template>
  <div class="AlarmLog">
    <div class="AlarmLog-top Card-b-c">
      <Titles Titles="报警记录" />
      <div class="search_menu">
        <div class="search_one">
          <span class="search_title" style="color: #666666">报警类型: </span>
          <el-radio-group v-model="searchValue.type">
            <el-radio label="0">全选</el-radio>
            <el-radio label="1">水位报警</el-radio>
            <el-radio label="2">电量报警</el-radio>
            <el-radio label="3">设备报警</el-radio>
            <el-radio label="4">门禁报警</el-radio>
            <el-radio label="5">非法入侵报警</el-radio>
          </el-radio-group>
        </div>
        <div class="search_one search_two">
          <div class="two_main">
            <span class="search_title" style="color: #666666">报警日期: </span>
            <el-date-picker
              :editable="false"
              style="margin-left: -10px"
              v-model="searchValue.time"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              prefix-icon="el-icon-time"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <div class="two_main">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button type="info" @click="handleReset">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="AlarmLog-bot">
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="alarmTime" label="报警时间"> </el-table-column>
        <el-table-column prop="type" label="报警类型"> </el-table-column>
        <el-table-column
          prop="reason"
          label="报警原因"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column label="处理状态">
          <template scope="scope">
            <span v-if="scope.row.status == 2">已处理</span>
            <span v-else class="immediate" style="color: #ff0000">未处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="handlerName" label="报警处理人">
        </el-table-column>
        <el-table-column prop="departmentName" width="250" label="操作">
          <template scope="scope">
            <el-button
              v-if="scope.row.status == 2"
              class="edit"
              style="width:calc(86/1920*100vw)"
              @click="handleDetail(scope.row, 1)"
              >查看详情</el-button
            >
            <el-button
              v-else
              class="immediate"
              style="width:calc(86/1920*100vw)"
              @click="handleDetail(scope.row, 2)"
              >立即处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.pageNum"
      >
      </el-pagination>
    </div>
    <!-- 新增用户 -->
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      @closed="closeBox"
      :destroy-on-close="true"
      :class="{ xiangQing: isActive }"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="alarmInfo" ref="ruleForm" :rules="rules">
          <el-form-item>
            <span class="label_r">报警类型</span>
            <el-input
              v-model="alarmInfo.type"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickName">
            <span class="label_r">报警时间</span>
            <el-input
              v-model="alarmInfo.alarmTime"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">报警处理人</span>
            <el-input
              v-model="alarmInfo.userName"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickName" class="reason">
            <span class="label_r">报警原因</span>
            <el-input
              v-model="alarmInfo.reason"
              class="xiangQing"
              resize="none"
              type="textarea"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="description">
            <span class="label_r">处理详情</span>
            <el-input
              type="textarea"
              resize="none"
              v-model="alarmInfo.description"
              :readonly="!status"
              maxlength="200"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span v-if="status" slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi">关闭</el-button>
        <el-button
          type="primary"
          @click="handleSave"
          style="margin-left:calc(30/1920*100vw)"
          >保存</el-button
        >
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi" type="primary"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Titles from "../../components/Titles";
import { alarmLogImmediate } from "../../api/index";
import { typeList } from "./index";
export default {
  components: { Titles },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
      searchValue: {
        type: "",
        time: []
      },
      value: "",
      // 分页
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0
      },
      typeList, //报警类型  1：水位报警 2：电量报警 3：设备报警 4：门禁报警 5非法入侵报警
      addShow: false,
      title: "查看详情",
      alarmInfo: {
        //
      },
      status: false, // 弹框类型
      loading: true,
      rules: {
        description: [
          { required: true, message: "请输入处理详情", trigger: "change" }
        ]
      },
      isActive: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    tableDataList() {
      this.tableData.forEach(item => {
        if (item.type == "1") {
          item.type = "水位报警";
        }
        if (item.type == "2") {
          item.type = "电量报警";
        }
        if (item.type == "3") {
          item.type = "设备报警";
        }
        if (item.type == "4") {
          item.type = "门禁报警";
        }
        if (item.type == "5") {
          item.type = "非法入侵报警";
        }
      });
    },
    getList() {
      this.loading = true;
      let { pageNum, size } = this.pagination;
      this.$get(`/alarmRecord/page/${pageNum}/${size}`).then(res => {
        this.pagination.total = res.data.total;
        this.tableData = res.data.list;
        this.tableDataList();
        this.loading = false;
      });
    },
    handleCurrent(page) {
      this.pagination.pageNum = page;
      this.pagination.size = 10;
      let { time, type } = this.searchValue;
      let { pageNum, size } = this.pagination;
      if (time.length) {
        if (type == 0) {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            startTime: time[0],
            endTime: time[1]
          }).then(res => {
            this.tableData = res.data.list;
            this.pagination.total = res.data.total;
          });
        } else {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            type,
            startTime: time[0],
            endTime: time[1]
          }).then(res => {
            this.tableData = res.data.list;
            this.pagination.total = res.data.total;
          });
        }
      } else {
        if (type == 0) {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {}).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        } else {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            type
          }).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        }
      }
    },
    // 查看详情 立即处理
    handleDetail(row, type) {
      this.title = type == 1 ? "报警详情" : "报警处理";
      // 详情
      if (type == 1) {
        this.status = false;
        this.isActive = true;
      } else {
        // 处理
        this.status = true;
      }
      this.alarmInfo = JSON.parse(JSON.stringify(row));
      this.alarmInfo.userName = localStorage.getItem("userName");
      this.addShow = true;
    },
    // 立即处理
    handleSave() {
      // 用户信息
      let { userId, userName } = JSON.parse(localStorage.getItem("userInfo"));
      // 报警信息
      let { description, id } = this.alarmInfo;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          alarmLogImmediate({
            handlerId: userId,
            handlerName: userName,
            description,
            id
          })
            .then(res => {
              this.$message({
                message: res.message,
                type: "success"
              });
              this.addShow = false;
              this.getList();
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.addShow = false;
            });
        } else {
          return false;
        }
      });

      // console.log(this.alarmInfo)
    },
    handleShow() {
      this.addShow = false;
      this.alarmInfo = {};
    },
    // 查询
    handleSearch() {
      this.loading = false;
      let { pageNum, size } = this.pagination;
      let { time, type } = this.searchValue;
      if (time.length) {
        if (type == 0) {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            startTime: time[0],
            endTime: time[1]
          }).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        } else {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            type,
            startTime: time[0],
            endTime: time[1]
          }).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        }
      } else {
        if (type == 0) {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {}).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        } else {
          this.$get(`/alarmRecord/page/${pageNum}/${size}`, {
            type
          }).then(res => {
            this.tableData = res.data.list;
            this.tableDataList();
            this.pagination.total = res.data.total;
          });
        }
      }
    },
    // 重置
    handleReset() {
      this.searchValue.time = [];
      this.searchValue.type = "0";
    },
    closeBox() {
      this.$refs["ruleForm"].resetFields();
      this.isActive = false;
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.AlarmLog {
  .AlarmLog-top {
    width: calc(1566 / 1920 * 100vw);
    height: calc(195 / 1080 * 100vh);
    margin-bottom: calc(22 / 1080 * 100vh);
    .search_menu {
      height: calc(147 / 1080 * 100vh);
      display: flex;
      flex-direction: column;
      div {
        height: 50%;
      }
      .search_one {
        width: calc(1150 / 1980 * 100vw);
        margin-left: calc(30 / 1980 * 100vw);
        display: flex;
        align-items: center;
        .search_title {
          margin-right: calc(53 / 1920 * 100vw);
          font-size: calc(18 / 1920 * 100vw);
        }
        .checkbox {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .el-radio {
          margin-right: calc(53 / 1920 * 100vw);
          ::v-deep .el-radio__label {
            font-size: calc(20 / 1920 * 100vw);
            color: #626262;
          }
        }
        ::v-deep .el-radio-group {
          height: calc(38 / 1080 * 100vh);
          line-height: calc(38 / 1080 * 100vh);
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep .el-radio {
            height: calc(38 / 1080 * 100vh);
            line-height: calc(38 / 1080 * 100vh);
          }
          .el-radio {
            margin-bottom: 0 !important;
          }
        }
      }
      .search_two {
        width: 100%;
        margin-left: 0;
        padding: 0 calc(30 / 1980 * 100vw);
        box-sizing: border-box;
        .two_main {
          width: 50%;
        }
        .two_main:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
  .AlarmLog-bot {
    ::v-deep .el-table {
      height: calc(675 / 1080 * 100vh);
      margin-bottom: calc(15 / 1080 * 100vh) !important;
    }
    ::v-deep .el-table__row {
      td {
        padding: calc(6 / 1080 * 100vw);
      }
    }
    ::v-deep .el-table__header-wrapper {
      height: calc(50 / 1080 * 100vh) !important;
    }
    ::v-deep .el-input__inner {
      border-bottom: none;
    }
  }
  ::v-deep .el-dialog {
    width: calc(388 / 1980 * 100vw) !important;
  }
  .contentAdd {
    padding-top: 20px;
  }
}
.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}
::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;
  .el-textarea__inner {
    height: 100%;
  }
}
::v-deep .el-input__inner {
  border: none;
  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
  border-radius: 0;
}
::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-table__row {
  height: calc(30 / 1080 * 100vh) !important;
}
.el-date-editor {
  border-radius: calc(4 / 1980 * 100vw);
}
.el-date-editor.el-range-editor.el-input__inner {
  width: calc(260 / 1920 * 100vw);
}
::v-deep .el-input__icon {
  font-size: calc(20 / 1920 * 100vw) !important;
  right: calc(3 / 1920 * 100vw) !important;
  cursor: pointer;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }
  .el-textarea__inner {
    background: #fff;
    margin-top: calc(5 / 1080 * 100vh);
  }
}
.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}
</style>
