export const typeList = [
  // {
  //   value: '1',
  //   label: '水位报警'
  // },
  // {
  //   value: '2',
  //   label: '电量报警'
  // },
  // {
  //   value: '3',
  //   label: '设备报警'
  // },
  // {
  //   value: '4',
  //   label: '门禁报警'
  // },
  // {
  //   value: '5',
  //   label: '非法入侵报警'
  // },
  '水位报警', '电量报警', '设备报警', '门禁报警', '非法入侵报警'
]