<template>
  <div class="title flex">
    <i class="line"></i>
    <div class="title-text font-s-20 font-w-b">{{ Titles }}</div>
    <!-- <div class="text" v-if="show">
      <p>{{ total }}张</p>
    </div> -->
    
    <!-- <div class="message" v-if="isShow">
      <span class="big">{{ policeTotal }}</span
      ><span class="small">/{{ totals }}</span>
    </div> -->
    
  </div>
</template>

<script>
export default {
  name: "Titles",
  props: {
    Titles: String,
    total: Number,
    show: true,
    isShow: true,
    totals: Number,
    policeTotal: Number
  },
  data() {
    return {
      data() {
        return {
          show: false,
          isShow: false
        };
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  height: calc(48 / 1080 * 100vh);
  .line::after {
    content: "";
    width: calc(3 / 1920 * 100vw);
    height: calc(17 / 1080 * 100vh);
    background-color: #3669ab;
    position: absolute;
    left: calc(17 / 1920 * 100vw);
    top: calc(17 / 1080 * 100vh);
  }
  .title-text {
    width: 87%;
    height: 100%;
    text-indent: calc(34 / 1920 * 100vw);
    line-height: calc(48 / 1080 * 100vh);
    color: #333;
  }
  border-bottom: calc(2 / 1080 * 100vh) #2c599b solid;
  .text {
    margin-top: calc(14 / 1080 * 100vh);
    width: calc(80 / 1920 * 100vw);
    // height: calc(24 / 1080 * 100vh);
    // padding: calc(4 / 1920 * 100vw);
    text-align: center;
    // background: #e3f2ff;
    border-radius: 12px;
    line-height: calc(24 / 1080 * 100vh);
    p {
      color: #2c599b;
      font-size: calc(18 / 1920 * 100vw);
      font-weight: bold;
    }
  }
  .message {
    position: relative;
    right: calc(20 / 1920 * 100vw);
    font-weight: bold;
    margin-top: calc(12 / 1080 * 100vh);
    color: #2c599b;
    .big {
      font-size: calc(20 / 1920 * 100vw);
    }
    .small {
    font-weight: normal;
      font-size: calc(16 / 1920 * 100vw);
      color: #2c2a37;
    }
  }
}
</style>
